// import store from "@/store"

export const state = () => ({})

export const mutations = {}

export const actions = {
  async fetchPartnership() {
    const { data } = await this.$api.get("/admin/common/partnership/list/")
    return data
  },
  async fetchPartnershipById(_, id) {
    const { data } = await this.$api.get(
      `/admin/common/partnership/${id}/update/`
    )
    return data
  }
}
