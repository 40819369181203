const en = {
  Add: "Add",
  IsStatic: "Is Static",
  HasCategory: "Has category?",
  Delete: "Delete",
  Edit: "Edit",
  goToSite: "Go to website",
  UsersColTitle: "Login",
  UsersColTitle2: "Action",
  UserTitle: "Users",
  FormFirstName: "First name",
  FormFirstNamePlace: "Enter your first name",
  FormLastName: "Last name",
  FormLastNamePlace: "Enter your last name",
  FormMiddleName: "Middle name",
  FormMiddleNamePlace: "Enter your middle name",
  FormBirthDate: "Birth date",
  FormGender: "Gender",
  FormMale: "Male",
  Decision: "Decision",
  FormFemale: "Female",
  FormUsername: "Username",
  FormUsernamePlace: "Enter your username",
  FormPassword: "Password",
  FormPasswordPlace: "Enter your password",
  FormDepartment: "Enter Control Department",
  FormPhone: "Information service",
  FormPhoneHelp: "Phone",
  FormPhonePlace: "Enter Information service",
  FormchancelleryPlace: "Enter chancellery",
  FormProfession: "Profession",
  FormProfessionPlace: "Enter your profession",
  FormRole: "Role",
  FormBirthPlace: "Birth place",
  FormBirthPlacePlaceholder: "Enter Your Birth place",
  FormWorkPlace: "Work place",
  FormWorkPlacePlaceholder: "Enter Your Work place",
  FormNat: "Nationality",
  FormNatPlace: "Enter Your Nationality",
  FormEdu: "Education",
  FormEduPlace: "Enter Your Education",
  FormUni: "University",
  FormUniPlace: "Enter Your University",
  FormSpec: "Speciality",
  FormSpecPlace: "Enter Your Speciality",
  FormCheckbox: "Is member council legislative chamber",
  FormAcademDeg: "Academic degree",
  FormAcademDegPlace: "Enter Your Academic degree",
  FormAcademtit: "Academic title",
  FormAcademtitPlace: "Enter Your Academic title",
  FormBodies: "Elected government bodies",
  FormOkrug: "Okrug",
  FormParty: "Party",
  FormPartyPlace: "Enter Your Party",
  FormState: "State awards",
  FormStatePlace: "Enter Your State awards",
  FormFraction: "Fraction",
  FormCommittee: "Committee",

  // Menu

  MenuTitle: "Menu name:",
  MenuTitlePlace: "Enter menu name",
  MenuGroup: "Group:",
  MenuGroupPlace: "Enter group",
  MenuParentName: "Select the parent menu:",
  MenuPosition: "Position:",

  // tags

  TagsColTitle: "Title",
  TagsColNumber: "Number",

  TableContent: "Content",
  TablePhoto: "Photo",
  TablePhotos: "Images",
  TableDoc: "Documents",
  TableHead: "Heading",
  TableDate: "Date of publication",
  Lang: "Languages",
  Content: "Content",
  IsActive: "Is active",
  Active: "Active",
  Cancel: "Cancellation",
  Save: "Save",
  SaveAndExit: "Save and exit",
  FileDoc: "Files documents",
  FileSelect: "select a file",
  LangContent: "Content languages",
  File: "File",
  Created: "Created",
  Create: "Create",
  AdressPlac: "Enter the address",
  Source: "Source",
  SourcePlac: "Enter source",
  Deputy: "Deputy",
  Video: "Video",

  // Fraction

  FractionEdit: "Edit factions",
  AddFraction: "Add factions",
  FractionTitle: "Fraction name",
  FractionTitlePlac: "Enter the name of the faction",
  InputText: "Input text here",
  Leader: "Leader",

  // Committe
  CommitTitle: "Committee name",
  CommitTitlePlac: "Enter the name of the committee",
  CommitEdit: "Edit committee",
  Chairman: "Chairman",
  SubChairman: "Sub chairman",

  Status: "Status",
  Url: "URL adress",
  EnterTheTitle: "Enter The Title",
  EnterStatus: "Enter Status",
  EnterUrl: "Enter URL adress",
  ListBroadcast: "The list of live broadcasts is empty!",

  selected: "selected",

  LawsEmpty: "The list of laws is empty!",
  TranslateCopyTo: "translate copy to",
  EnterTitle: "Enter title",
  DateOfPublication: "Date of publication",
  Close: "Close",
  AreasEmpty: "The list of areas is empty!",
  AddArea: "Add area",
  Region: "Region",
  CountiesEmpty: "The list of counties is empty!",
  AddCommissions: "Add commissions",
  Loading: "Loading...",
  UploadDocToServer: "Upload documents to the server",
  Downloads: "Downloads",
  Consider: "Consider",
  Answer: "Answer",
  ViewAnswer: "View Answer",
  Adress: "Adress",
  EnterAdress: "Enter adress",
  SenderStatus: "Sender status",
  ToWhom: "To whom",
  Passport: "Passport",
  Chancery: "Chancery",
  EnterChanceryNumb: "Enter the office number",
  Faks: "Faks",
  EnterFaks: "Enter your faks",
  Email: "Email",
  EnterEmail: "Enter your email",
  Bus: "Buses",
  EnterBuses: "Enter bus numbers",
  MiniBus: "Minibuses",
  EnterMinibuses: "Enter the numbers of minibuses",
  Metro: "Metro",
  EnterMetro: "Enter the metro station",
  ReferencePoint: "Reference point",
  EnterLandmark: "Enter a landmark",
  WorkingTime: "Working time",

  // me
  headerLang: "Header languages",
  allNews: "All news",
  search: "Search",
  exit: "exit",
  exitToSite: "Do you want to logout?",
  back: "Back",
  title: "Title",
  publicationDate: "Publication date",
  ingradient: "Content",
  Img: "Image",
  Documents: "Documents",
  documentFiles: "Document files",
  Choose: "Choose",
  Specialty: "Specialty",
  Degree: "Degree",

  Published: "Опубликован",
  NotPublished: "Не опубликован",

  APopconfirm: "Вы действительно хотите удалить?",
  Yes: "Да",
  No: "Нет",
  SucDelete: "Меню успешно удален",
  NoDelete: "Ошибка при удалении",
  NoDelete2: "Не удалось удалить",

  SelectMenu: "Выберите меню",
  positionPlac: "Введите позицию",
  EnterUrlLink: "Введите url(для внешних ссылок)",
  EditorErrTitle: "Введите контент на одном из языков.",
  ImageStorages: "Хранилище изображений",
  ReleaseHome: "Релиз на главной странице",
  EditorErrTitle2: "Введите заголовок на одном из языков.",
  On: "Вкл",
  Off: "Выкл",
  All: "Все",
}

export default en