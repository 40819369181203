export const state = () => ({})

export const mutations = {}

export const actions = {
  async fetchContacts() {
    const { data } = await this.$api.patch("/admin/contact/create/")
    return data
  },
  async getContact() {
    const { data } = await this.$api.get("/admin/contacts/list")
    return data
  }
}