const uz = {
  Add: "Qo'shish",
  IsStatic: "Statik",
  HasCategory: "Kategoriyasi mavjudmi?",
  Delete: "O'chirish",
  Edit: "Tahrir-sh",
  Status: "Status",
  Url: "URL manzili",
  EnterTheTitle: "Nomini kiriting",
  EnterStatus: "Statusni kiriting",
  EnterUrl: "URL manzilni kiriting",
  ListBroadcast: "Jonli efirlar ro'yxati bo'sh!",
  goToSite: "Saytga o`tish",
  Decision: "Qaror",
  LawsEmpty: "Qonunlar ro'yxati bo'sh!",
  TranslateCopyTo: "ga tarjima qilish",
  EnterTitle: "Sarlavha kiriting",
  DateOfPublication: "Nashr qilingan sana",
  Close: "Yopish",
  AreasEmpty: "Hududlar ro'yxati bo'sh!",
  AddArea: "Maydon qo'shish",
  Region: "Hudud",
  CountiesEmpty: "Viloyatlar ro'yxati bo'sh!",
  AddCommissions: "Komissiyalar qo'shing",
  Loading: "Yuklanmoqda...",
  UploadDocToServer: "Hujjatlarni serverga yuklash",
  Downloads: "Yuklamalar",
  Consider: "O'ylab ko'ring",
  Answer: "Javob berish",
  ViewAnswer: "Javobni ko'rish",
  Adress: "Manzil",
  EnterAdress: "Manzilni kiriting",
  SenderStatus: "Yuboruvchi xolati",
  ToWhom: "Kimga",
  Passport: "Pasport",
  Chancery: "Devonxona",
  EnterChanceryNumb: "Devonxona raqamini kiriting",
  Faks: "Faks",
  EnterFaks: "Faksni kiriting",
  Email: "Pochta manzili",
  EnterEmail: "Pochta manzilingizni kiriting",
  Bus: "Avtobuslar",
  EnterBuses: "Avtobus raqamini kiriting",
  MiniBus: "Marshrutlar",
  EnterMinibuses: "Marshrut raqamini kiriting",
  Metro: "Metro",
  EnterMetro: "Metro bekatini kiriting",
  ReferencePoint: "Mo'ljal",
  EnterLandmark: "Mo'ljalni kiriting",
  WorkingTime: "Ish vaqti",

  UsersColTitle: "Login",
  UsersColTitle2: "Xarakat",
  UserTitle: "Foydalanuvchilar",
  FormFirstName: "Ism",
  FormFirstNamePlace: "Ismingizni kiriting",
  FormLastName: "Familiya",
  FormLastNamePlace: "Familiyangizni kiriting",
  FormMiddleName: "Sharif",
  FormMiddleNamePlace: "Sharifingizni kiriting",
  FormBirthDate: "Tug‘ilgan yil",
  FormGender: "Jins",
  FormMale: "Erkak",
  FormFemale: "Ayol",
  FormUsername: "Foydalanuvchi nomi",
  FormUsernamePlace: "Foydalanuvchi nomini kiriting",
  FormPassword: "Parol",
  FormPasswordPlace: "Parolingizni kiriting",
  FormPhone: "Axborot xizmati",
  FormPhoneHelp: "Telefon",
  FormPhonePlace: "Axborot xizmati raqamini kiriting",
  FormchancelleryPlace: "Kanselyariya kiriting",
  FormDepartment: "Nazorat bo'limi kiriting",
  FormProfession: "Professiya",
  FormProfessionPlace: "Professiya",
  FormRole: " Rol",
  FormBirthPlace: "Tug'ilgan joy",
  FormBirthPlacePlaceholder: "Tug'ilgan joy kiriting",
  FormWorkPlace: "Ish joyi",
  FormWorkPlacePlaceholder: "Ish Joyini kiriting",
  FormNat: "Millat",
  FormNatPlace: "Millatingizni kiriting",
  FormEdu: "Ma'lumot",
  FormEduPlace: "Ma'lumot kiriting",
  FormUni: "Oqish joyi",
  FormUniPlace: "Oqish joyini kiriting",
  FormSpec: "Mutaxassislik",
  FormSpecPlace: "Mutaxassislikni kiriting",
  FormCheckbox: "Is member council legislative chamber",
  FormAcademDeg: "Ilmiy darajasi",
  FormAcademDegPlace: "Ilmiy darajasi kiriting",
  FormAcademtit: "Ilmiy unvoni",
  FormAcademtitPlace: "Ilmiy unvoni kiriting",
  FormBodies: "Saylangan davlat organlari",
  FormOkrug: "Okrug",
  FormParty: "Partiya",
  FormPartyPlace: "Partiyani kiriting",
  FormState: "Davlat mukofotlari",
  FormStatePlace: "Davlat mukofotlarini kiriting",
  FormFraction: "Fraktsiya",
  FormCommittee: "Qo'mita",

  // Menu

  MenuTitle: "Menyu nomi:",
  MenuTitlePlace: "Menyu nomini kiriting",
  MenuGroup: "Gruppa:",
  MenuGroupPlace: "Gruppani kiriting",
  MenuParentName: "menyuni tanlang:",
  MenuPosition: "Pozitsiya:",

  notOnMain: "Bosh menu emas",
  onMain: "Bosh menu",

  // Tags

  TagColTitle: "Sarlavha",

  TableContent: "Tarkib",
  TablePhoto: "Rasm",
  TablePhotos: "Rasmlar",
  TableDoc: "Hujjatlar",
  TableHead: "Sarlavha",
  TableDate: "Nashr qilingan sana",
  Lang: "Tillar",
  Content: "Tarkib",
  IsActive: "Faol",
  Active: "Faol",
  Cancel: "Bekor qilish",
  Save: "Saqlash",
  SaveAndExit: "Saqlash va chiqish",
  FileDoc: "Hujjatlar fayllari",
  FileSelect: "faylni tanlang",
  LangContent: "Tarkib tillari",
  File: "Fayl",
  Created: "Yaratilgan",
  Create: "Yaratmoq",
  AdressPlac: "Manzilni kiriting",
  Source: "Manba",
  SourcePlac: "Manbani kiriting",
  Deputy: "Deputat",
  Video: "Video",

  // Fraction

  FractionEdit: "Fraksiyani tahrirlash",
  AddFraction: "Fraksiya qo'shing",
  FractionTitle: "Fraksiya nomi",
  FractionTitlePlac: "Fraksiya nomini kiriting",
  InputText: "Matnni kiriting",
  Leader: "Rahbar",

  // Committe
  CommitTitle: "Qo'mita nomi",
  CommitTitlePlac: "Komissiya nomini kiriting",
  CommitEdit: "Qo'mitani tahrirlash",
  Chairman: "Rais",
  SubChairman: "Rais o'rinbosari",

  TagsColTitle: "Nomi",
  TagsColNumber: "Raqami",
  selected: "tanlangan",

  // me
  headerLang: "Sarlavha tillari",
  allNews: "Barcha yangiliklar",
  search: "Qidirish",
  exit: "chiqish",
  exitToSite: " Hisobdan chiqmoqchimisiz?",
  back: "Orqaga",
  title: "Sarlavha",
  publicationDate: "Nashr qilingan sana",
  ingradient: "Tarkib",
  Img: "Rasm",
  Documents: "Hujjatlar",
  documentFiles: "Hujjat fayllari",
  Choose: "Tanlang",
  Specialty: "Mutaxassisligi",
  postCount: "Soni",
  count: "ta",
  Degree: "Ma'lumoti",
  Published: "Chop etilgan",
  NotPublished: "Chop etilmagan",
  APopconfirm: "Haqiqatan ham oʻchirib tashlamoqchimisiz?",
  Yes: "Ha",
  No: "Yo'q",
  SucDelete: "Menyu muvaffaqiyatli olib tashlandi",
  NoDelete: "Oʻchirishda xatolik yuz berdi",
  NoDelete2: "Oʻchirib boʻlmadi",
  SelectMenu: "Menyuni tanlang",
  EnterUrlLink: "URLni kiriting (tashqi havolalar uchun)",
  ReleaseHome: "Bosh sahifada chiqarish",
  EditorErrTitle: "Tarkibni tillardan birida kiriting",
  ImageStorages: "Tasvirni saqlash",
  EditorErrTitle2: "Tillardan birida sarlavha kiriting.",
  On: "Yoniq",
  Off: "Oʻchirilgan",
  All: "Barchasi",
  positionPlac: "Pazitsiyasini kiriting",
  PlenarySession: "Yalpi majlis",
  IsDate: "Sanasi",
  YouTubeLink: "YouTube link",
  CommissionsMembers: "Komissiya a'zolari",
  CommissionsPowers: "Komissiyasi vakolatlari",
  Alias: "Alias",
  EnterAlias: "Alias kiriting",
  NoAlias: "Bu Alias topilmadi",
  Sections: "Bo'limlar",
  Select: "Tanlang",
  CommitteeMembers: "Qo‘mita aʼzolari",
  CommitteeCharter: "Qo‘mita nizomi",
  CommitteeWorkPlan: "Qo‘mita ish-rejasi",
  LegislativeActivity: "Qonunchilik faoliyati",
  MonitoringActivities: "Nazorat-tahlil faoliyati",
  Events: "Tadbirlar",
  HandlingApplications: "Murojaatlar bilan ishlash",
  EmployeeType: "Xodim turi",
  UploadImage: "Rasm yuklash",
  FullName: "F.I.O",
  Position: "Lavozim",
  PositionPlace: "Lavozimni kiriting",
  Tasks: "Vazifalari",
  Senator: "Senator",
  Order: "Tartibi",
  Biography: "O'zi xaqida ma'lumotlari",
  PositionEnter: "Pozitsiya kiriting",
  SocialNetwork: "Ijtimoiy tarmoq",
  Type: "Turi",
  Value: "Qiymati",
  Placeholder: "Kiriting",
  Employee: "Xodim",
  DevonianActivity: "Devon faoliyati",
  Departments: "Boshqarma va bo‘limlar",
  Link: "Havola",
  Articles: "Maqolalar",
  IsArticles: "Maqolalari",
  ManagementName: "Boshqaruv nomi",
  More: "Barchasi",
  OfficeSenate: "Senat devoni",
  Answers: "Javoblar",
  QuestionText: "Savol matni",
  OrthographicLover: "Imloviy xato",
  Description: "Tavsif",
  DataTransfers: "Yuborilgan vaqti",
  linkError: "Havola xato",
  Go: "O'tish",
  Fixed: "Tugallangan",
  NoFixed: "Tugallanmagan",
  NotIndicated: "Belgilanmagan",
  PublicationName: "Nashir nomi",
  SelectSenator: "Senatorni tanlang",
  Question: "Savol",
  Result: "Natija",
  Subtitle: "Quyi sarlavha",
  RemoveSelected: "Tanlanganni olib tashlash",
  DeleteSelected: "Tanlangan oʻchirish",
  AllDelete: "Barchasini o'chirish",

};
export default uz;


