export const state = () => ({
  list: []
})
export const getters = {
  list: (state) => state.list || []
}
export const mutations = {
  deleteItem(state, payload) {
    const f = state.list && state.list.findIndex((item) => item.id == payload)
    if (f != -1) {
      state.list = [...state.list.slice(0, f), ...state.list.slice(f + 1)]
    }
  },
  setList(state, payload) {
    state.list = payload || []
  }
}

export const actions = {
  fetch({
    commit
  }, params) {
    return this.$api
      .get("/admin/request/list", {
        params
      })
      .then((res) => {
        if (res && res.data) {
          commit("setList", res.data)
          return res.data || []
        }
      })
      .catch((err) => {
        console.log(err)
        return []
      })
  },
  removeItemAction({
    commit
  }, payload) {
    return this.$api
      .delete(`/admin/request/${payload}`)
      .then((res) => {
        commit("deleteItem", payload)
        return res
      })
      .catch((err) => {
        console.log(err)
        return err
      })
  },
}