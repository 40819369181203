export const state = () => ({
    list: []
})

export const getters = {
    list(state) {
        return (state && state.list) || []
    }
}

export const mutations = {
    setList: (state, payload) => (state.list = payload || []),

    createItem(state, payload) {
        state.list = [...state.list, payload]
    },

    updateItem(state, payload) {
        const f = state.list.findIndex((item) => item.id == payload.id)

        if (f != -1) {
            state.list = [
                ...state.list.slice(0, f),
                payload,
                ...state.list.slice(f + 1)
            ]
        }
    },

    deleteItem(state, payload) {
        const f = state.list.findIndex((item) => item.id == payload)

        if (f != -1) {
            state.list = [...state.list.slice(0, f), ...state.list.slice(f + 1)]
        }
    }
}

export const actions = {
    fetch({
        commit
    }, payload) {
        return this.$api
            .get("/admin/menu/list", {
                params: payload
            })
            .then((res) => {
                if (res && res.data && res.data.status) {
                    commit("setList", res.data.data)
                    return res.data || []
                }

                return []
            })
            .catch((err) => {
                console.log(err)
                return []
            })
    },

    fetchAll({
        commit
    }, payload) {
        return this.$api
            .get("/admin/menu/list/all", {
                params: payload
            })
            .then((res) => {
                if (res && res.data) {
                    commit("setList", res.data)
                    return res.data || []
                }

                return []
            })
            .catch((err) => {
                console.log(err)
                return []
            })
    },

    detail({
        commit
    }, payload) {
        return this.$api
            .get("/admin/menu/" + payload)
            .then((res) => {
                if (res && res.data) {
                    console.log(res);
                    commit("setList", res.data)
                    return res.data
                }

                return []
            })
            .catch((err) => { 
                console.log(err)
                return []
            })
    },

    createItemAction({
        commit
    }, payload) {
        return this.$api
            .post("/admin/menu/create/", payload.data)
            .then((res) => {
                commit("createItem", res.data)
                return res
            })
            .catch((err) => {
                console.log(err)
                return err
            })
    },

    updateItemAction(_, payload) {
        return this.$api
            .patch("/admin/menu/" + payload.id + "/update/", payload.data)
            .then((res) => {
                return res
            })
            .catch((err) => {
                console.log(err)
                return err
            })
    },

    removeItemAction({
        commit
    }, payload) {
        return this.$api
            .delete("/admin/menu/true-delete/" + payload)
            .then((res) => {
                console.log(res);
                if (res && res.status >= 200 && res.data.status) {
                    commit("deleteItem", payload)
                    return true
                }
                return false
            })
            .catch((err) => {
                console.log(err)
                return false
            })
    }
}