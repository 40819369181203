import "@/store"

export const state = () => ({
  list: []
})

export const getters = {
  list: (state) => state.list || []
}
export const mutations = {
  setList(state, payload) {
    state.list = payload || []
  },
  createItem(state, payload) {
    state.list = [...state.list, payload]
  }

  // deleteItem(state, payload) {
  //   const f = state.list.findIndex((item) => item.id == payload)

  //   if (f != -1) {
  //     state.list = [...state.list.slice(0, f), ...state.list.slice(f + 1)]
  //   }
  // }
}

export const actions = {
  fetch({ commit }, params) {
    return this.$api
      .get("/admin/common/innovationidea/list/", {
        params
      })
      .then((res) => {
        if (res && res.data) {
          commit("setList", res.data)
          return res.data || []
        }
      })
      .catch((err) => console.log(err))
  },
  detail(_, payload) {
    return this.$api
      .get("/admin/common/innovationidea/" + payload + "/detail/")
      .then((res) => {
        if (res && res.data) {
          return res.data || {}
        }
      })
      .catch((err) => console.log(err))
  }
  // removeItem({ commit }, payload) {
  //   return this.$api
  //     .delete("/admin/feedback/" + payload + "/delete/")
  //     .then((res) => {
  //       if (res && res.status >= 200) {
  //         commit("deleteDivision", payload)
  //         return true
  //       }
  //       return false
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       return false
  //     })
  // }
}
