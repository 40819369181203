export const actions = {
    fetch(_, params) {
        return this.$api
            .get("/admin/post/list", {
                params
            })
            .then((res) => {
                return res && res.data
            })
    },
    createPost(_, payload) {
        return this.$api.post("/admin/post/create", payload).then((res) => {
            return res && res.data
        })
    },
    deletePost(_, payload) {
        return this.$api
            .delete("/admin/post/" + payload)
            .then((res) => {
                return res
            })
    },
    deleteOrgPost(_, payload) {
        return this.$api
            .delete("/admin/post/" + payload + "/delete")
            .then((res) => {
                return res
            })
    }
}