export const actions = {
    async fetchList(_, { params }) {
        let res = await this.$api.get("/admin/post/list", {
            params
        })
        return res && res.data
    },
    async create(_, payload) {
        let res = await this.$api.post("/admin/post/create", payload)
        return res && res.status
    },
    async fetchDetail(_, id) {
        let res = await this.$api.get(`/admin/post/${id}/update`)
        return res && res.data
    }
}