// import store from "@/store"

export const state = () => ({
  typeList: [
    {
      id: 1,
      name: {
        uz: 'Foydali havolalar',
        oz: 'Фойдали ҳаволалар',
        ru: 'Полезные ссылки',
        en: 'Useful links'
      },
      group: "useful_links"
    },
    // {
    //   id: 2,
    //   name: {
    //     uz: 'Interaktiv xizmatlar',
    //     oz: 'Интерактив хизматлар',
    //     ru: 'Интерактивные услуги',
    //     en: 'Interactive services',
    //   },
    //   group: "interactive_services"
    // },
    {
      id: 3,
      name: {
        uz: 'Qabul jadvali',
        oz: 'Қабул жадвали',
        ru: 'График приема',
        en: 'График приема',
      },
      group: "reception"
    },
    {
      id: 4,
      name: {
        uz: 'Statistika',
        oz: 'Статистика',
        ru: 'Статистика',
        en: 'Statistica',
      },
      group: "statistics"
    }
  ]
})

export const getters = {
  typeList: (state) => {return state.typeList}
}
export const mutations = {}

export const actions = {

  // async fetchTypeList() {
  //   try {
  //     const { data } = await this.$api.get("/admin/item-type/list")
  //     return data
  //   } catch (error) {
  //     console.error(error)
  //   }
  // },
  // async fetchTypeDetailList(_, { params }) {
  //   try {
  //     const { data } = await this.$api.get("/admin/item/list", { params })
  //     return data
  //   } catch (error) {
  //     console.error(error)
  //   }
  // },

}
