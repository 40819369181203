export const state = () => ({
    list: []
})

export const getters = {
    list: (state) => state.list || []
}

export const mutations = {
    setOpendata(state, payload) {
        state.list = payload || []
    },
}

export const actions = {
    fetch({ commit }, params) {
        return this.$api
            .get("/admin/open-data/list", {
                params
            })
            .then((res) => {
                const { data } = res
                if (data && data.data && data.data.results) {
                    commit("setOpendata", data.data.results)
                    return data.data.results || []
                }
            })
            .catch((err) => {
                console.log(err)
            })
    },
    detail(_, payload) {
        return this.$api
            .get("/admin/open-data/" + payload)
            .then((res) => {
                return (res && res.data) || {}
            })
            .catch((err) => {
                console.log(err)
            })
    },
    remove(_, payload) {
        return this.$api
            .delete("/admin/open-data/" + payload)
            .then((res) => {
                if (res && res.data) {
                    return res.data
                }
            })
            .catch((err) => {
                console.log(err)
                return false
            })
    },
    removeAnswer(_, payload) {
        return this.$api
            .delete("/admin/open-data/" + payload)
            .then((res) => {
                if (res && res.data) {
                    return res.data
                }
            })
            .catch((err) => {
                console.log(err)
                return false
            })
    }
}