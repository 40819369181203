const oz = {
  Add: "Қўшиш",
  IsStatic: "Статик",
  HasCategory: "Категорияси мавжудми?",
  Delete: "Ўчириш",
  Edit: "Таҳрир-ш",
  goToSite: "Сайтга ўтиш",
  Decision: "Қарор",
  UsersColTitle: "Логин",
  UsersColTitle2: "Ҳаракат",
  UserTitle: "Фойдаланувчилар",
  FormFirstName: "Исм",
  FormFirstNamePlace: "Исмингизни киритинг",
  FormLastName: "Фамилия",
  FormLastNamePlace: "Фамилиянгизни киритинг",
  FormMiddleName: "Шариф",
  FormMiddleNamePlace: "Шарифингизни киритинг",
  FormBirthDate: "Туғилган йил",
  FormGender: "Жинс",
  FormMale: "Эркак",
  FormFemale: "Айол",
  FormUsername: "Фойдаланувчи номи",
  FormUsernamePlace: "Фойдаланувчи номини киритинг",
  FormPassword: "Пароль",
  FormPasswordPlace: "Паролингизни киритинг",
  FormPhone: "Ахборот хизмати",
  FormPhoneHelp: "Телефон",
  FormPhonePlace: "Ахборот хизмати ракамини киритинг",
  FormchancelleryPlace: "Kанцелярия киритинг",
  FormDepartment: "Отдел контроля киритинг",
  FormProfession: "Профессия",
  FormProfessionPlace: "Профессия",
  FormRole: " Rol ",
  FormBirthPlace: "Тугилган жой",
  FormBirthPlacePlaceholder: "Тугилган жойингизни киритинг",
  FormWorkPlace: "Иш жойи",
  FormWorkPlacePlaceholder: "Иш Жойини киритинг",
  FormNat: "Миллат",
  FormNatPlace: "Миллатингизни киритинг",
  FormEdu: "Маълумот",
  FormEduPlace: "Маълумот киритинг",
  FormUni: "Оқиш жойи",
  FormUniPlace: "Оқиш жойини киритинг",
  FormSpec: "Мутахассислик",
  FormSpecPlace: "Мутахассисликни киритинг",
  FormCheckbox: "Is member council legislative chamber",
  FormAcademDeg: "Илмий даражаси",
  FormAcademDegPlace: "Илмий даражаси киритинг",
  FormAcademtit: "Илмий унвони",
  FormAcademtitPlace: "Илмий унвони киритинг",
  FormBodies: "Сайланган давлат органлари",
  FormOkrug: "Округ",
  FormParty: "Партия",
  FormPartyPlace: "Партияни киритинг",
  FormState: "Давлат мукофотлари",
  FormStatePlace: "Давлат мукофотларини киритинг",
  FormFraction: "Фрактсия",
  FormCommittee: "Қўмита",

  //Menu

  MenuTitle: "Меню номи:",
  MenuTitlePlace: "Меню номини киритинг",
  MenuGroup: "Группа:",
  MenuGroupPlace: "Группани киритинг",
  MenuParentName: "Менюни тангланг:",
  MenuPosition: "Позитсия:",

  notOnMain: "Бош мену",
  onMain: "Бош мену эмас",
  // Tags

  TagColTitle: "Сарлавҳа",

  TableContent: "Таркиб",
  TablePhoto: "Расм",
  TablePhotos: "Расмлар",
  TableDoc: "Ҳужжатлар",
  TableHead: "Сарлавҳа",
  TableDate: "Нашр қилинган сана",
  Lang: "Тиллар",
  Content: "Таркиб",
  IsActive: "Фаол",
  Active: "Фаол",
  Cancel: "Бекор қилиш",
  Save: "Сақлаш",
  SaveAndExit: "Сақлаш ва чиқиш",
  FileDoc: "Ҳужжатлар файллари",
  FileSelect: "файлни танланг",
  LangContent: "Таркиб тиллари",
  File: "Файл",
  Created: "Яратилган",
  Create: "Яратмоқ",
  AdressPlac: "Манзилни киритинг",
  Source: "Манба",
  SourcePlac: "Манбани киритинг",
  Deputy: "Депутат",
  Video: "Видео",

  // Fraction

  FractionEdit: "Фракцияни таҳрирлаш",
  AddFraction: "Фракция қўшинг",
  FractionTitle: "Фракция номи",
  FractionTitlePlac: "Фракция номини киритинг",
  InputText: "Матнни киритинг",
  Leader: "Раҳбар",

  // Committe
  CommitTitle: "Қўмита номи",
  CommitTitlePlac: "Қўмита номини киритинг",
  CommitEdit: "Қўмитани таҳрирлаш",
  Chairman: "Раис",
  SubChairman: "Раис ўринбосари",

  Status: "Статус",
  Url: "URL манзили",
  EnterTheTitle: "Номини киритинг",
  EnterStatus: "Статусни киритинг",
  EnterUrl: "URL манзилни киритинг",
  ListBroadcast: "Жонли эфирлар руйхати буш!",

  LawsEmpty: "Конунлар руйхати буш!",
  TranslateCopyTo: "га таржима килиш",
  EnterTitle: "Сарлавхани киритинг",
  DateOfPublication: "Нашр килинган сана",
  Close: "Ёпиш",
  AreasEmpty: "Худудлар руйхати буш!",
  AddArea: "Майдон кушиш",
  Region: "Ҳудуд",
  CountiesEmpty: "Вилоятлар руйхати буш",
  AddCommissions: "Комиссиялар кушинг",
  Loading: "Юкланмокда...",
  UploadDocToServer: "Хужжатларни серверга юклаш",
  Downloads: "Юкламалар",
  Consider: "Уйлаб куринг",
  Answer: "Жавоб бериш",
  ViewAnswer: "Жавобни куриш",
  Adress: "Манзил",
  EnterAdress: "Манзилни киритинг",
  SenderStatus: "Юборувчи холати",
  ToWhom: "Кимга",
  Passport: "Паспорт",
  Chancery: "Девонхона",
  EnterChanceryNumb: "Девонхона ракамини киритинг",
  Faks: "Факс",
  EnterFaks: "Факсни киритинг",
  Email: "Почта манзили",
  EnterEmail: "Почта манзилингизни киритинг",
  Bus: "Автобуслар",
  EnterBuses: "Автобус ракамини киритинг",
  MiniBus: "Маршрутлар",
  EnterMinibuses: "Маршрут ракамини киритинг",
  Metro: "Метро",
  EnterMetro: "Метро бекатини киритинг",
  ReferencePoint: "Мулжал",
  EnterLandmark: "Мулжални киритинг",
  WorkingTime: "Иш вакти",

  TagsColTitle: "Номи",
  TagsColNumber: "Рақами",
  selected: "танланган",

  // me
  headerLang: "Сарлавҳа тиллари",
  allNews: "Барча янгиликлар",
  search: "Қидириш",
  exit: "чиқиш",
  exitToSite: "Ҳисобдан чиқмоқчимисиз?",
  back: "Орқага",
  title: "Сарлавҳа",
  publicationDate: "Нашр қилинган сана",
  ingradient: "Таркиб",
  Img: "Расм",
  Documents: "Ҳужжатлар",
  documentFiles: "Ҳужжат файллари",
  Choose: "Танланг",
  postCount: "Сони",
  count: "та",
  Specialty: "Мутахассислиги",
  Degree: "Маълумоти",
  Yes: "Ҳа",
  No: "Йўқ",
  Published: "Чоп этилган",
  NotPublished: "Чоп этилмаган",

  SucDelete: "Меню муваффақиятли олиб ташланди",
  NoDelete: "Ўчиришда хатолик юз берди",
  NoDelete2: "Ўчириб бўлмади",
  SelectMenu: "Менюни танланг",
  positionPlac: "Позицияни киритинг",
  ReleaseHome: "Бош саҳифада чиқариш",
  EnterUrlLink: "УРЛни киритинг (ташқи ҳаволалар учун)",
  EditorErrTitle: "Таркибни тиллардан бирида киритинг",
  ImageStorages: "Тасвирни сақлаш",
  EditorErrTitle2: "Тиллардан бирида сарлавҳа киритинг.",
  On: "Ёниқ",
  Off: "Ўчирилган",
  All: "Барчаси",
  PlenarySession: "Ялпи мажлис",
  IsDate: "Санаси",
  YouTubeLink: "YouTube линк",
  CommissionsMembers: "Комиссия Азолари",
  CommissionsPowers: "Комиссияси ваколатлари",
  Alias: "Alias",
  EnterAlias: "Алиас киритинг",
  NoAlias: "Бу Алиас мавжуд эмас",
  Sections: "Бўлимлар",
  Select: "Танланг",
  CommitteeMembers: "Қўмита аъзолари",
  CommitteeCharter: "Қўмита низоми",
  CommitteeWorkPlan: "Қўмита иш-режаси",
  LegislativeActivity: "Қонунчилик фаолияти",
  MonitoringActivities: "Назорат-таҳлил фаолияти",
  Events: "Тадбирлар",
  HandlingApplications: "Мурожаатлар билан ишлаш",
  EmployeeType: "Ходим тури",
  UploadImage: "Расм юклаш",
  FullName: "Ф.И.О",
  Position: "Лавозим",
  PositionPlace: "Лавозимни киритинг",
  Tasks: "Вазифалари",
  Senator: "Сенатор",
  Order: "Тартиби",
  Biography: "Биография",
  PositionEnter: "Позиция киритинг",
  SocialNetwork: "Ижтимоий тармоқ",
  Type: "Тури",
  Value: "Қиймати",
  Placeholder: "Киритинг",
  Employee: "Ходим",
  DevonianActivity: "Девон фаолияти",
  Departments: "Бошқарма ва бўлимлар",
  Link: "Ҳавола",
  Articles: "Мақолалар",
  IsArticles: "Мақолалари",
  ManagementName: "Бошқарув номи",
  More: "Барчаси",
  OfficeSenate: "Сенат девони",
  PublicationName: "Нашр номи",
  SelectSenator: "Сенаторни тангланг",
  Question: "Савол",
  Result: "Натижа",
  OrthographicLover: "Имловий хато",
  Description: "Тавсиф",
  DataTransfers: "Юборилган вақти",
  linkError: "Ҳавола хато",
  Go: "Ўтиш",
  Fixed: "Тугалланган",
  NoFixed: "Тугалланмаган",
  NotIndicated: "Белгиланмаган",
  Answers: "Жавоблар",
  QuestionText: "Савол матни",
  Subtitle: "Қуйи сарлавҳа",
  RemoveSelected: "Танланганни олиб ташлаш",
  DeleteSelected: "Танланган ўчириш",
  AllDelete: "Барчасини ўчириш",




};

export default oz;
