import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
    routes: [{
                path: "/",
                component: () =>
                    import ( /* webpackChunkName: "default" */ "./layouts/default"),

                children: [{
                        path: "",
                        component: () =>
                            import ( /* webpackChunkName: "index-page" */ "./views")
                    },
                    // users
                    {
                        path: "/users",
                        name: "users",
                        component: () =>
                            import ( /* webpackChunkName: "users" */ "./views/users"),
                        meta: {
                            dataUrl: "users",
                            parent: "users",
                            title: {
                                uz: "Foydalanuvchilar",
                                oz: "Фойдаланувчилар",
                                ru: "Пользователи",
                                en: "Users"
                            },
                            iconName: "team",
                            roles: ["admin"]
                        }
                    },

                    // user create
                    {
                        path: "/user/create",
                        name: "user-create",
                        component: () =>
                            import (
                                /* webpackChunkName: "user-create" */
                                "./views/users/detail"
                            ),
                        meta: {
                            parent: "users",
                            roles: ["admin"]
                        }
                    },

                    // user detail
                    {
                        path: "/user/:id",
                        name: "user-detail",
                        component: () =>
                            import (
                                /* webpackChunkName: "user-detail" */
                                "./views/users/detail"
                            ),
                        meta: {
                            parent: "users",
                            roles: ["admin"]
                        }
                    },

                    // menu
                    {
                        path: "/menu",
                        name: "menu",
                        component: () =>
                            import ( /* webpackChunkName: "menu-list" */ "./views/menu"),
                        meta: {
                            dataUrl: "menu",
                            title: {
                                uz: "Menu",
                                oz: "Меню",
                                ru: "Меню",
                                en: "Menu"
                            },
                            iconName: "hdd",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // menu detail
                    {
                        path: "/menu/create",
                        name: "menu-create",
                        component: () =>
                            import ( /* webpackChunkName: "menu-create" */ "./views/menu/detail"),
                        meta: {
                            parent: "menu",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // menu detail
                    {
                        path: "/menu/:id",
                        name: "menu-detail",
                        component: () =>
                            import ( /* webpackChunkName: "menu-detail" */ "./views/menu/detail"),
                        meta: {
                            parent: "menu",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Ялпи мажлислар
                    {
                        path: "/plenary-sessions",
                        name: "plenary-sessions",
                        component: () =>
                            import ("./views/plenary-sessions"),
                        meta: {
                            dataUrl: "plenary-sessions",
                            title: {
                                oz: "Ялпи мажлислар",
                                uz: "Yalpi majlislar",
                                ru: "Пленарные заседания",
                                en: "Plenary meetings"
                            },
                            iconName: "audit",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Ялпи мажлислар create
                    {
                        path: "/plenary-sessions/create",
                        name: "plenary-sessions-create",
                        component: () =>
                            import ("./views/plenary-sessions/create.vue"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // Ялпи мажлислар update
                    {
                        path: "/plenary-sessions/update/:id",
                        name: "plenary-sessions-update",
                        component: () =>
                            import ("./views/plenary-sessions/_id.vue"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },
                    // Ялпи мажлислар
                    {
                        path: "/plenary-sessions-live",
                        name: "plenary-sessions-live",
                        component: () =>
                            import ("./views/plenary-sessions-live"),
                        meta: {
                            dataUrl: "plenary-sessions-live",
                            title: {
                                oz: "Ялпи мажлислар (live)",
                                uz: "Yalpi majlislar (live)",
                                ru: "Пленарные заседания (live)",
                                en: "Plenary meetings (live)"
                            },
                            iconName: "audit",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // posts
                    {
                        path: "/posts/:menu_id",
                        name: "posts",
                        component: () =>
                            import ("./views/posts"),
                        meta: {
                            iconName: "notification",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // post create
                    {
                        path: "/posts/:menu_id/create",
                        name: "post-create",
                        component: () =>
                            import ("./views/posts/create"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // post update
                    {
                        path: "/posts/:menu_id/update/:id",
                        name: "post-update",
                        component: () =>
                            import ("./views/posts/_id"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // Кенгаш мажлислари
                    {
                        path: "/council-meetings",
                        name: "council-meetings",
                        component: () =>
                            import ("./views/council-meetings"),
                        meta: {
                            dataUrl: "council-meetings",
                            title: {
                                oz: "Кенгаш мажлислари",
                                uz: "Kengash majlislari",
                                ru: "Заседания совета",
                                en: "Council meetings",
                            },
                            iconName: "audit",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Кенгаш мажлислари create
                    {
                        path: "/council-meetings/create",
                        name: "council-meetings-create",
                        component: () =>
                            import ("./views/council-meetings/create"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // Кенгаш мажлислари update
                    {
                        path: "/council-meetings/update/:id",
                        name: "council-meetings-update",
                        component: () =>
                            import ("./views/council-meetings/_id"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // Тадбирлар
                    {
                        path: "/events",
                        name: "events",
                        component: () =>
                            import ("./views/events"),
                        meta: {
                            dataUrl: "events",
                            title: {
                                oz: "Тадбирлар",
                                uz: "Tadbirlar",
                                ru: "Мероприятия",
                                en: "Events"
                            },
                            iconName: "unordered-list",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // Халқаро ҳамкорлик
                    {
                        path: "/international-cooperation",
                        name: "international-cooperation",
                        component: () =>
                            import ("./views/international-cooperation"),
                        meta: {
                            dataUrl: "international-cooperation",
                            title: {
                                oz: "Халқаро ҳамкорлик",
                                uz: "Xalqaro hamkorlik",
                                ru: "Международное сотрудничество",
                                en: "International cooperation"
                            },
                            iconName: "gateway",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // Назорат-таҳлил фаолияти 
                    {
                        path: "/controlactivity",
                        name: "controlactivity",
                        component: () =>
                            import ("./views/controlactivity"),
                        meta: {
                            dataUrl: "controlactivity",
                            title: {
                                oz: "Назорат-таҳлил фаолияти",
                                uz: "Nazorat-tahlil faoliyati",
                                ru: "Контрольно-аналитическая деятельность",
                                en: "Control and analytical activities"
                            },
                            iconName: "bar-chart",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Ҳаракатлар стратегияси
                    {
                        path: "/action-strategy",
                        name: "action-strategy",
                        component: () =>
                            import ("./views/action-strategy"),
                        meta: {
                            dataUrl: "action-strategy",
                            title: {
                                oz: "Ҳаракатлар стратегияси",
                                uz: "Harakatlar strategiyasi",
                                ru: "Стратегия действий",
                                en: "Action strategy"
                            },
                            iconName: "interaction",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Ҳаракатлар стратегияси create
                    {
                        path: "/action-strategy/create",
                        name: "action-strategy-create",
                        component: () =>
                            import ("./views/action-strategy/create.vue"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // Ҳаракатлар стратегияси update
                    {
                        path: "/action-strategy/update/:id",
                        name: "action-strategy-update",
                        component: () =>
                            import ("./views/action-strategy/_id.vue"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // ОAВ даги чиқишлар
                    {
                        path: "/publication",
                        name: "publication",
                        component: () =>
                            import ("./views/publication"),
                        meta: {
                            dataUrl: "publication",
                            title: {
                                oz: "ОAВ даги чиқишлар",
                                uz: "OAV dagi chiqishlar",
                                ru: "Публикации",
                                en: "Publication"
                            },
                            iconName: "notification",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // ОAВ даги чиқишлар create
                    {
                        path: "/publication/create",
                        name: "publication-create",
                        component: () =>
                            import ("./views/publication/create.vue"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // ОAВ даги чиқишлар update
                    {
                        path: "/publication/update/:id",
                        name: "publication-update",
                        component: () =>
                            import ("./views/publication/_id.vue"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // Qonunlar
                    {
                        path: "/laws",
                        name: "laws",
                        component: () =>
                            import ("./views/laws"),
                        meta: {
                            dataUrl: "laws",
                            title: {
                                oz: "Қонунлар",
                                uz: "Qonunlar",
                                ru: "Законы",
                                en: "Laws"
                            },
                            iconName: "container",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Qonunlar create
                    {
                        path: "/laws/create",
                        name: "laws-create",
                        component: () =>
                            import ("./views/laws/create.vue"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // Qonunlar update
                    {
                        path: "/laws/update/:id",
                        name: "laws-update",
                        component: () =>
                            import ("./views/laws/_id.vue"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // Qarorlar
                    {
                        path: "/decisions",
                        name: "decisions",
                        component: () =>
                            import ("./views/decisions"),
                        meta: {
                            dataUrl: "decisions",
                            title: {
                                oz: "Қарорлар",
                                uz: "Qarorlar",
                                ru: "Постановления",
                                en: "Decisions"
                            },
                            iconName: "file-done",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // Komissiyalar
                    {
                        path: "/senat-commissions",
                        name: "senat-commissions",
                        component: () =>
                            import ("./views/senat-commissions"),
                        meta: {
                            dataUrl: "senat-commissions",
                            title: {
                                oz: "Комиссиялар",
                                uz: "Komissiyalar",
                                ru: "Комиссии",
                                en: "Commissions"
                            },
                            iconName: "file-done",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // senat commissions members
                    {
                        path: "/senat-commissions-members/:menu_id",
                        name: "senat-commissions-members",
                        component: () =>
                            import ("./views/senat-commissions-members"),
                        meta: {
                            roles: ["admin", "moderator"]
                        }
                    },
                    // senat commissions posts
                    {
                        path: "/senat-commissions-posts/:menu_id",
                        name: "senat-commissions-posts",
                        component: () =>
                            import ("./views/senat-commissions-posts"),
                        meta: {
                            iconName: "notification",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // senat commissions posts update
                    {
                        path: "/senat-commissions-posts/:menu_id/update/:id",
                        name: "senat-commissions-posts-update",
                        component: () =>
                            import ("./views/senat-commissions-posts/_id"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // committees members
                    {
                        path: "/commissions-members/:menu_id",
                        name: "commissions-members",
                        component: () =>
                            import ("./views/commissions-members"),
                        meta: {
                            roles: ["admin", "moderator"]
                        }
                    },
                    // Qo'mitalar
                    {
                        path: "/committees",
                        name: "committees",
                        component: () =>
                            import ("./views/committees"),
                        meta: {
                            dataUrl: "committees",
                            title: {
                                oz: "Қўмиталар",
                                uz: "Qo'mitalar",
                                ru: "Комитеты",
                                en: "Committees"
                            },
                            iconName: "file-done",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // committees posts
                    {
                        path: "/committees-posts/:menu_id",
                        name: "committees-posts",
                        component: () =>
                            import ("./views/committees-posts"),
                        meta: {
                            iconName: "notification",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // committees posts update
                    {
                        path: "/committees-posts/:menu_id/update/:id",
                        name: "committees-posts-update",
                        component: () =>
                            import ("./views/committees-posts/_id"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // committees members
                    {
                        path: "/committees-members/:menu_id",
                        name: "committees-members",
                        component: () =>
                            import ("./views/committees-members"),
                        meta: {
                            roles: ["admin", "moderator"]
                        }
                    },
                    // Respublika Komissiyalari
                    {
                        path: "/commissions",
                        name: "commissions",
                        component: () =>
                            import ("./views/commissions"),
                        meta: {
                            dataUrl: "commissions",
                            title: {
                                oz: "Республика Комиссиялари",
                                uz: "Respublika Komissiyalari",
                                ru: "Комиссии",
                                en: "Commissions"
                            },
                            iconName: "file-done",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // commissions events
                    {
                        path: "/commissions-events/:menu_id",
                        name: "commissions-events",
                        component: () =>
                            import ("./views/commissions-events"),
                        meta: {
                            iconName: "notification",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // commissions events update
                    {
                        path: "/commissions-events/:menu_id/update/:id",
                        name: "commissions-events-update",
                        component: () =>
                            import ("./views/commissions-events/_id"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // commissions docs
                    {
                        path: "/commissions-docs/:menu_id",
                        name: "commissions-docs",
                        component: () =>
                            import ("./views/commissions-docs"),
                        meta: {
                            iconName: "notification",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // commissions docs update
                    {
                        path: "/commissions-docs/:menu_id/update/:id",
                        name: "commissions-docs-update",
                        component: () =>
                            import ("./views/commissions-docs/_id"),
                        meta: {
                            roles: ["admin", "moderator"]
                        }
                    },

                    // tag list
                    {
                        path: "/tags",
                        name: "tags",
                        component: () =>
                            import ("./views/tags"),
                        meta: {
                            dataUrl: "tags",
                            title: {
                                uz: "Teglar",
                                oz: "Теглар",
                                ru: "Теги",
                                en: "Tags"
                            },
                            iconName: "tags",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // tags detail
                    {
                        path: "/tags/update/:id",
                        name: "tags-detail",
                        component: () =>
                            import ( /* webpackChunkName: "menu-detail" */ "./views/tags/_id.vue"),
                        meta: {
                            parent: "tags",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // tags create
                    {
                        path: "/tags/create",
                        name: "tags-create",
                        component: () =>
                            import ( /* webpackChunkName: "menu-detail" */ "./views/tags/create.vue"),
                        meta: {
                            parent: "tags",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Senators
                    {
                        path: "/senators",
                        name: "senators",
                        component: () =>
                            // import ("./views/senators"),
                            import ("./views/staffs"),
                        meta: {
                            dataUrl: "senators",
                            title: {
                                oz: "Ходимлар",
                                uz: "Xodimlar",
                                ru: "Сотрудники",
                                en: "Employees"
                            },
                            iconName: "layout",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // staffs
                    {
                        path: "/senators/staffs",
                        name: "staffs",
                        component: () =>
                            import ( /* webpackChunkName: "staffs-list" */ "./views/staffs"),
                        meta: {
                            // iconName: "layout",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // staffs-create create
                    {
                        path: "/senators/staffs/create",
                        name: "staffs-create",
                        component: () =>
                            import (
                                /* webpackChunkName: "staffs-upload" */
                                "./views/staffs/create"
                            ),
                        meta: {
                            roles: ["admin", "moderator"]
                        }
                    },

                    // staffs update
                    {
                        path: "/senators/staffs/:id",
                        name: "staffs-detail",
                        component: () =>
                            import (
                                /* webpackChunkName: "staffs-upload" */
                                "./views/staffs/_id"
                            ),
                        meta: {
                            roles: ["admin", "moderator"]
                        }
                    },

                    {
                        path: "/senators/department",
                        name: "department",
                        component: () =>
                            /* webpackChunkName: "department" */
                            import ("./views/department"),
                        meta: {
                            // roles: ["admin", "moderator"],
                            // dataUrl: "department",
                            iconName: "cluster"
                        }
                    },
                    // department create
                    {
                        path: "/senators/department/create",
                        name: "department-create",
                        component: () =>
                            /* webpackChunkName: "department-create" */
                            import ("./views/department/create"),
                        meta: {
                            parent: "department"
                                // roles: ["admin", "moderator"]
                        }
                    },
                    // department detail
                    {
                        path: "/senators/department/:id",
                        name: "department-detail",
                        component: () =>
                            /* webpackChunkName: "department-detail" */
                            import ("./views/department/_id"),
                        meta: {
                            parent: "department"
                                // roles: ["admin", "moderator"]
                        }
                    },

                    // Maqolalar
                    {
                        path: "/senators/article/:senator",
                        name: "article",
                        component: () =>
                            import ("./views/article"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // Maqolalar create update
                    {
                        path: "/senators/article/:senator/update/:id",
                        name: "article-update",
                        component: () =>
                            import ("./views/article/_id.vue"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },
                    // Senat rahbariyati
                    {
                        path: "/senate-leadership",
                        name: "senate-leadership",
                        component: () =>
                            import ("./views/senate-leadership"),
                        meta: {
                            dataUrl: "senate-leadership",
                            title: {
                                oz: "Сенат раҳбарияти",
                                uz: "Senat rahbariyati",
                                ru: "Сенатское руководство",
                                en: "Senat rahbariyati"
                            },
                            iconName: "audit",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // Senat Rahbariyati authority
                    {
                        path: "/senate-leadership-authority/:menu_id",
                        name: "senate-leadership-authority",
                        component: () =>
                            import ("./views/senate-leadership-authority"),
                        meta: {
                            iconName: "notification",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // Senat Rahbariyati authority update
                    {
                        path: "/senate-leadership-authority/:menu_id/update/:id",
                        name: "senate-leadership-authority-update",
                        component: () =>
                            import ("./views/senate-leadership-authority/_id"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // Senat kengashi
                    {
                        path: "/senate-council",
                        name: "senate-council",
                        component: () =>
                            import ("./views/senate-council"),
                        meta: {
                            dataUrl: "senate-council",
                            title: {
                                oz: "Сенат кенгаши",
                                uz: "Senat kengashi",
                                ru: "Совет Сената",
                                en: "Council of the Senate"
                            },
                            iconName: "audit",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // Senat kengashi authority
                    {
                        path: "/senate-council-authority/:menu_id",
                        name: "senate-council-authority",
                        component: () =>
                            import ("./views/senate-council-authority"),
                        meta: {
                            iconName: "notification",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // Senat kengashi authority update
                    {
                        path: "/senate-council-authority/:menu_id/update/:id",
                        name: "senate-council-authority-update",
                        component: () =>
                            import ("./views/senate-council-authority/_id"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // Senat devoni
                    {
                        path: "/senate-office",
                        name: "senate-office",
                        component: () =>
                            import ("./views/senate-office"),
                        meta: {
                            dataUrl: "senate-office",
                            title: {
                                oz: "Сенат девони",
                                uz: "Senat devoni",
                                ru: "Канцелярия Сената",
                                en: "Office of the Senate"
                            },
                            iconName: "audit",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Senat Devoni Posts
                    {
                        path: "/senate-office-posts/:menu_id",
                        name: "senate-office-posts",
                        component: () =>
                            import ("./views/senate-office-posts"),
                        meta: {
                            iconName: "notification",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // Senat Devoni Posts update
                    {
                        path: "/senate-office-posts/:menu_id/update/:id",
                        name: "senate-office-posts-update",
                        component: () =>
                            import ("./views/senate-office-posts/_id"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // Senat сорови
                    {
                        path: "/senate-inquiries",
                        name: "senate-inquiries",
                        component: () =>
                            import ("./views/senate-inquiries"),
                        meta: {
                            dataUrl: "senate-inquiries",
                            title: {
                                oz: "Сенатор сўрови",
                                uz: "Senator so'rovi",
                                ru: "Запрос сенатора",
                                en: "Inquiries of the Senate"
                            },
                            iconName: "audit",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // Senat сорови create
                    {
                        path: "/senate-inquiries/create",
                        name: "senate-inquiries-create",
                        component: () =>
                            import ("./views/senate-inquiries/create.vue"),
                        meta: {
                            dataUrl: "senate-inquiries",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Senat сорови update
                    {
                        path: "/senate-inquiries/update/:id",
                        name: "senate-inquiries-detail",
                        component: () =>
                            import ("./views/senate-inquiries/_id.vue"),
                        meta: {
                            dataUrl: "senate-inquiries",
                            roles: ["admin", "moderator"]
                        }
                    },

                    {
                        path: "/senate-inquiries/posts",
                        name: "senate-posts",
                        component: () =>
                            import ("./views/senate-inquiries/posts"),
                        meta: {
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Senat сорови create
                    {
                        path: "/senate-inquiries/posts/create",
                        name: "senate-posts-create",
                        component: () =>
                            import ("./views/senate-inquiries/posts/create.vue"),
                        meta: {
                            dataUrl: "senate-inquiries",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Senat сорови update
                    {
                        path: "/senate-inquiries/posts/update/:id",
                        name: "senate-posts-update",
                        component: () =>
                            import ("./views/senate-inquiries/posts/_id.vue"),
                        meta: {
                            dataUrl: "senate-inquiries",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // {
                    //     path: "/ministry/organizations",
                    //     name: "ministry-organizations",
                    //     component: () =>
                    //         import("./views/ministry_organization"),
                    //     meta: {
                    //         title: {
                    //             uz: "Vazirlik tassarufidagi tashkilotlar",
                    //             oz: "Вазирлик тассаруфидаги ташкилотлар",
                    //             ru: "Организации при Министерстве",
                    //             en: "Organizations under the Ministry"
                    //         },
                    //         dataUrl: "ministry/organizations",
                    //         iconName: "deployment-unit"
                    //         // roles: ["admin", "moderator"]
                    //     }
                    // },

                    // organization detail
                    {
                        path: "/ministry/organization/:id",
                        name: "ministry-organizations-detail",
                        component: () =>
                            /* webpackChunkName: "Digitalization" */
                            import ("./views/ministry_organization/organization-detail"),
                        meta: {
                            parent: "ministry-organizations",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // organization create
                    {
                        path: "/ministry/organization-create",
                        name: "ministry-organizations-create",
                        component: () =>
                            /* webpackChunkName: "Digitalization" */
                            import ("./views/ministry_organization/organization-create"),
                        meta: {
                            parent: "ministry-organizations",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Фотогалерея
                    {
                        path: "/gallery",
                        name: "gallery",
                        component: () =>
                            import ("./views/gallery"),
                        meta: {
                            dataUrl: "gallery",
                            title: {
                                oz: "Фотогалерея",
                                uz: "Fotogalereya",
                                ru: "Фотогалерея",
                                en: "Gallery"
                            },
                            iconName: "file-image",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Фотогалерея create
                    {
                        path: "/gallery/create",
                        name: "gallery-create",
                        component: () =>
                            import ("./views/gallery/create.vue"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // Фотогалерея update
                    {
                        path: "/gallery/update/:id",
                        name: "gallery-update",
                        component: () =>
                            import ("./views/gallery/_id.vue"),
                        meta: {
                            // roles: ["admin", "moderator"]
                        }
                    },

                    // items list
                    {
                        path: "/items",
                        name: "items",
                        component: () =>
                            /* webpackChunkName: "usefull-link" */
                            import ("./views/items"),
                        meta: {
                            title: {
                                oz: "Инфо",
                                uz: "Info",
                                ru: "Инфо",
                                en: "Info"
                            },
                            dataUrl: "items",
                            iconName: "link",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // items detail
                    {
                        path: "/items/:item_group",
                        name: "items-detail",
                        component: () =>
                            /* webpackChunkName: "usefull-link-detail" */
                            import ("./views/items/detail"),
                        meta: {
                            parent: "items",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // items create
                    {
                        path: "/items/:item_group/create",
                        name: "items-create",
                        component: () =>
                            /* webpackChunkName: "item-create" */
                            import ("./views/items/detail/create"),
                        meta: {
                            parent: "items",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // items update
                    {
                        path: "/items/:item_group/update/:id",
                        name: "items-update",
                        component: () =>
                            /* webpackChunkName: "item-create" */
                            import ("./views/items/detail/id"),
                        meta: {
                            parent: "items",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // statistic list
                    {
                        path: "/statistic",
                        name: "statistic",
                        component: () =>
                            /* webpackChunkName: "usefull-link" */
                            import ("./views/statistic"),
                        meta: {
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Catalog list
                    {
                        path: "/catalog",
                        name: "catalog",
                        component: () =>
                            /* webpackChunkName: "usefull-link" */
                            import ("./views/catalog"),
                        meta: {
                            title: {
                                oz: "Каталог",
                                uz: "Katalog",
                                ru: "Каталог",
                                en: "Catalog"
                            },
                            dataUrl: "catalog",
                            iconName: "link",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // catalog detail
                    {
                        path: "/catalog/:category",
                        name: "catalog-detail",
                        component: () =>
                            /* webpackChunkName: "usefull-link-detail" */
                            import ("./views/catalog/detail"),
                        meta: {
                            parent: "catalog",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // contacts
                    {
                        path: "/contacts",
                        name: "contacts",
                        component: () =>
                            /* webpackChunkName: "contacts" */
                            import ("./views/contacts"),
                        meta: {
                            title: {
                                uz: "Bog'lanish",
                                oz: "Боғланиш",
                                ru: "Контакты",
                                en: "Contacts"
                            },
                            dataUrl: "contacts",
                            iconName: "contacts",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // spelling-mistake
                    {
                        path: "/spelling-mistake",
                        name: "spelling-mistake",
                        component: () =>
                            /* webpackChunkName: "fractions" */
                            import ("./views/spelling-mistake"),
                        meta: {
                            title: {
                                uz: "Imloviy xato",
                                oz: "Имловий хато",
                                ru: "Орфографическая ошибка",
                                en: "Spelling mistake"
                            },
                            dataUrl: "spelling-mistake",
                            iconName: "profile",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // spelling-mistake
                    {
                        path: "/spelling-mistake/:id/detail",
                        name: "spelling-mistake-detail",
                        component: () =>
                            /* webpackChunkName: "fractions" */
                            import ("./views/spelling-mistake/_id"),
                        meta: {
                            roles: ["admin", "moderator"]
                        }
                    },

                    // faq
                    {
                        path: "/faq",
                        name: "faq",
                        component: () => /* webpackChunkName: "faq" */
                            import ("./views/faq"),
                        meta: {
                            title: {
                                uz: "Ko'p so'raladigan savollar",
                                oz: "Кўп сўраладиган саволлар",
                                ru: "Часто задаваемые вопросы",
                                en: "Faq"
                            },
                            roles: ["admin", "moderator"],
                            dataUrl: "faq",
                            iconName: "question-circle"
                        }
                    },
                    // faq create
                    {
                        path: "/faq/create",
                        name: "faq-create",
                        component: () =>
                            /* webpackChunkName: "faq" */
                            import ("./views/faq/create"),
                        meta: {
                            parent: "faq",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // faq detail
                    {
                        path: "/faq/:id",
                        name: "faq-detail",
                        component: () =>
                            /* webpackChunkName: "faq" */
                            import ("./views/faq/_id"),
                        meta: {
                            parent: "faq",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // question
                    {
                        path: "/question",
                        name: "question",
                        component: () => /* webpackChunkName: "faq" */
                            import ("./views/question"),
                        meta: {
                            title: {
                                uz: "So'rovnoma",
                                oz: "Сўровнома",
                                ru: "Опрос",
                                en: "Questionnaire"
                            },
                            roles: ["admin", "moderator"],
                            dataUrl: "question",
                            iconName: "question-circle"
                        }
                    },

                    // question answer
                    {
                        path: "/question/:id",
                        name: "question-detail",
                        component: () =>
                            /* webpackChunkName: "faq" */
                            import ("./views/question/answer"),
                        meta: {
                            parent: "question",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // Ochiq ma'lumotlar
                    {
                        path: "/opendata",
                        name: "opendata",
                        component: () =>
                            import ("./views/opendata"),
                        meta: {
                            dataUrl: "opendata",
                            title: {
                                oz: "Очиқ маълумотлар",
                                uz: "Ochiq ma'lumotlar",
                                ru: "Открытые данные",
                                en: "Open data"
                            },
                            iconName: "file-done",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // interactive-services list
                    // {
                    //   path: "/interactive-services",
                    //   name: "interactiveServices",
                    //   component: () =>
                    //     /* webpackChunkName: "interactive-services" */
                    //     import("./views/interactive-services"),
                    //   meta: {
                    //     title: {
                    //       uz: "Interaktiv xizmatlar",
                    //       oz: "Интерактив хизматлар",
                    //       ru: "Интерактивные услуги",
                    //       en: "Interactive services"
                    //     },
                    //     dataUrl: "interactive-services",
                    //     iconName: "interaction",
                    //     roles: ["admin", "moderator"]
                    //   }
                    // },

                    // interactive-services detail
                    {
                        path: "/interactive-services/:id",
                        name: "interactiveServices-detail",
                        component: () =>
                            /* webpackChunkName: "interactive-services" */
                            import ("./views/interactive-services/interactive-services-detail"),
                        meta: {
                            parent: "interactiveServices",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // interactive-services create
                    {
                        path: "/interactive-services-create",
                        name: "interactiveServices-create",
                        component: () =>
                            /* webpackChunkName: "interactive-services" */
                            import ("./views/interactive-services/interactive-services-create"),
                        meta: {
                            parent: "interactiveServices",
                            roles: ["admin", "moderator"]
                        }
                    },

                    // {
                    //     path: "/region",
                    //     name: "region",
                    //     component: () =>
                    //         /* webpackChunkName: "fractions" */
                    //         import("./views/region"),
                    //     meta: {
                    //         title: {
                    //             uz: "Regionlar",
                    //             oz: "Регионлар",
                    //             ru: "Регион",
                    //             en: "Region"
                    //         },

                    //         dataUrl: "region",
                    //         iconName: "profile"
                    //         // roles: ["admin", "moderator"]
                    //     }
                    // },
                    // region create
                    {
                        path: "/region/create",
                        name: "region-create",
                        component: () =>
                            import (
                                /* webpackChunkName: "user-create" */
                                "./views/region/create.vue"
                            ),
                        meta: {
                            parent: "region"
                                // roles: ["admin", "moderator"]
                        }
                    },
                    //region detail
                    {
                        path: "/region/:id",
                        name: "region-detail",
                        component: () =>
                            import ("./views/region/detail.vue"),
                        meta: {
                            parent: "region"
                                // roles: ["admin", "moderator"]
                        }
                    },
                    // subdistrict create
                    {
                        path: "/subdistrict/create",
                        name: "subdistrict-create",
                        component: () =>
                            import (
                                /* webpackChunkName: "user-create" */
                                "./views/subdistrict/create.vue"
                            ),
                        meta: {
                            parent: "subdistrict"
                                // roles: ["admin", "moderator"]
                        }
                    },
                    // subdistrict detail
                    {
                        path: "/subdistrict/:id",
                        name: "subdistrict-detail",
                        component: () =>
                            import ("./views/subdistrict/detail.vue"),
                        meta: {
                            parent: "subdistrict"
                                // roles: ["admin", "moderator"]
                        }
                    },
                    // notification
                    // {
                    //     path: "/notification",
                    //     name: "notification",
                    //     component: () =>
                    //         import(
                    //             /* webpackChunkName: "notification" */
                    //             "./views/notification"
                    //         ),
                    //     meta: {
                    //         dataUrl: "notification",
                    //         title: {
                    //             uz: "Оповещения",
                    //             oz: "Оповещения",
                    //             ru: "Оповещения",
                    //             en: "Notification"
                    //         },
                    //         iconName: "notification"
                    //         // roles: ["admin", "moderator"]
                    //     }
                    // },
                    // notification-detail
                    {
                        path: "/notification/:id",
                        name: "notification-detail",
                        component: () =>
                            import (
                                /* webpackChunkName: "reports" */
                                "./views/notification/_id"
                            ),
                        meta: {
                            parent: "notification",
                            roles: ["admin", "moderator"]
                        }
                    },
                    // reports
                    // {
                    //     path: "/reports",
                    //     name: "reports",
                    //     component: () =>
                    //         import( /* webpackChunkName: "reports" */ "./views/reports"),
                    //     meta: {
                    //         dataUrl: "reports",
                    //         title: {
                    //             uz: "Сообщить об угрозе",
                    //             oz: "Сообщить об угрозе",
                    //             ru: "Сообщить об угрозе",
                    //             en: "Reports"
                    //         },
                    //         iconName: "hdd",
                    //         roles: ["admin", "moderator"]
                    //     }
                    // },
                    // reposrt-detail
                    {
                        path: "/reports/:id",
                        name: "report-detail",
                        component: () =>
                            import ( /* webpackChunkName: "reports" */ "./views/reports/_id"),
                        meta: {
                            parent: "reports",
                            roles: ["admin", "moderator"]
                        }
                    }
                ]
            },
            {
                path: "/login",
                component: () =>
                    import ( /* webpackChunkName: "login" */ "./layouts/loginWindow")
            },
            {
                // Not Found
                path: "*",
                // redirect: '/',
                component: () =>
                    import ( /* webpackChunkName: "anypage" */ "./layouts/notFound")
            }
        ]
        // mode: 'history'
})