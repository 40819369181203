export const state = () => ({})

export const mutations = {}

export const actions = {
    async fetchOrgTypeList() {
        const { data } = await this.$api.get("/admin/fake_sites/fake-site-category/list/")
        console.log(data)
        return data
    },
    createOrgType(_, payload) {
        return this.$api
            .post("/admin/fake_sites/fake-site-category/create/", payload)
            .then((res) => {
                console.log(res)
                return res
            })
            .catch((err) => {
                console.log(err)
                return err
            })
    },
}