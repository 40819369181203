export const state = () => ({
  list: []
})

export const getters = {
  list: (state) => state.list || []
}

export const mutations = {
  setQues(state, payload) {
    state.list = payload || []
  },
  delete(state, payload) {
    const findIndex = state.list.findIndex((item) => item.id == payload)
    if (findIndex != -1) {
      state.list = [
        ...state.list.slice(0, findIndex),
        ...state.list.slice(findIndex + 1)
      ]
    }
  }
}

export const actions = {
  fetch({ commit }, params) {
    return this.$api
      .get("/admin/faq/list", {
        params
      })
      .then((res) => {
        const { data } = res
        if (data && data.data && data.data.results) {
          commit("setQues", data.data.results)
          return data.data.results || []
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  detail(_, payload) {
    return this.$api
      .get("/admin/faq/" + payload)
      .then((res) => {
        return (res && res.data) || {}
      })
      .catch((err) => {
        console.log(err)
      })
  },
  update(_, {form, id}) {
    return this.$api
      .put("/admin/faq/" + id, form)
      .then((res) => {
        return (res && res.data) || {}
      })
      .catch((err) => {
        console.log(err)
      })
  },
  create(_, {form}) {
    return this.$api
      .post("/admin/faq/create", form)
      .then((res) => {
        return res.data || {}
      })
      .catch((err) => {
        console.log(err)
      })
  },
  remove(_, payload) {
    return this.$api
      .delete("/admin/faq/" + payload)
      .then((res) => {
        if (res && res.data) {
          return res.data
        }
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }
}
