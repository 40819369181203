const ru = {
  Add: "Добавить",
  IsStatic: "Статичность",
  HasCategory: "Есть категория?",
  Delete: "Удалить",
  Edit: "Ред-ть",
  Decision: "Решение",
  UsersColTitle: "Логин",
  UsersColTitle2: "Действие",
  UserTitle: "Пользователи",
  FormFirstName: "Имя",
  FormFirstNamePlace: "Введите Ваше имя",
  FormLastName: "Фамилия",
  FormLastNamePlace: "Введите Вашу фамилию",
  FormMiddleName: "Отечество",
  FormMiddleNamePlace: "Введите Ваше отечество",
  FormBirthDate: "Дата рождения",
  FormGender: "Пол",
  FormMale: "Мужчина",
  FormFemale: "Женщина",
  FormUsername: "Имя пользователя",
  FormUsernamePlace: "Введите имя пользователя",
  FormPassword: "Пароль",
  FormPasswordPlace: "Введите Ваш пароль",
  FormPhone: "Информационная служба",
  FormPhoneHelp: "Телефон",
  FormPhonePlace: "Введите Информационная служба",
  FormchancelleryPlace: "Введите канцелярия",
  FormDepartment: "Введите Отдел контроля",
  FormProfession: "Профессия",
  FormProfessionPlace: "Профессия",
  FormRole: " Роль",
  FormBirthPlace: "Место рождения",
  FormBirthPlacePlaceholder: "Введите Ваше место рождения",
  FormWorkPlace: "Место работы",
  FormWorkPlacePlaceholder: "Введите Ваше место работы",
  FormNat: "Национальность",
  FormEdu: "Образование",
  FormUni: "University",
  FormUniPlace: "Enter Your University",
  FormSpec: "Специальность",
  FormCheckbox: "Является членом совета законодательной палаты",
  FormAcademDeg: "Академическая степень",
  FormAcademDegPlace: "Напишите Вашу академическую степень",
  FormAcademtit: "Академическая звание",
  FormAcademtitPlace: "Напишите Вашу академическое звание",
  FormBodies: "Избранных органов государственной власти",
  FormOkrug: "Округ",
  FormParty: "Партия",
  FormPartyPlace: "Напишите партию",
  FormState: "Государственные награды",
  FormFraction: "Фракция",
  FormCommittee: "Комитет",

  // Menu

  MenuTitle: "Название меню:",
  MenuTitlePlace: "Введите название меню",
  MenuGroup: "Группа:",
  MenuGroupPlace: "Введите группу",
  MenuParentName: "Выберите родительский меню:",
  MenuPosition: "Позиция:",
  PositionEnter: "Введите позицию",

  // Tags

  TagsColTitle: "Название",
  TagsColNumber: "Номер",

  TableContent: "Содержимое",
  TablePhoto: "Фото",
  TablePhotos: "Изображения",
  TableDoc: "Документы",
  TableHead: "Заголовок",
  TableDate: "Дата публикации",
  Lang: "Языки",
  Content: "Контент",
  IsActive: "Активен",
  Active: "Актив",
  Cancel: "Отмена",
  Save: "Сохранить",
  SaveAndExit: "Сохранить и выйти",
  FileDoc: "Файлы документы",
  FileSelect: "Выберите файл",
  LangContent: "Языки контента",
  File: "Файлы",
  Created: "Создано",
  Create: "Создать",
  AdressPlac: "Введите адрес",
  Source: "Источник",
  SourcePlac: "Введите источник",
  Deputy: "Депутат ",
  Video: "Видео",

  // Fraction

  FractionEdit: "Редактировать фракции",
  AddFraction: "Добавить фракции",
  FractionTitle: "Название фракции",
  FractionTitlePlac: "Введите название фракции",
  InputText: "Введите текст здесь",
  Leader: "Лидер",

  // Committe
  CommitTitle: "Название комитета",
  CommitTitlePlac: "Введите название комитета",
  CommitEdit: "Редактировать комитет",
  Chairman: "Председатель",
  SubChairman: "Заместитель председателя",
  Status: "Статус",
  Url: "URL адресс",
  EnterTheTitle: "Введите название",
  EnterStatus: "Введите статус",
  EnterUrl: "Введите URL адресс",
  ListBroadcast: "Список прямых эфиров пуст!",

  LawsEmpty: "Список законов пуст!",
  TranslateCopyTo: "Перевести копию на",
  EnterTitle: "Введите заголовок",
  DateOfPublication: "Дата публикации",
  Close: "Ёпиш",
  AreasEmpty: "Список областей пуст!",
  AddArea: "Добавить область",
  Region: "Область",
  CountiesEmpty: "Список округов пуст!",
  AddCommissions: "Добавить комиссии",
  Loading: "Идет загрузка...",
  UploadDocToServer: "Загрузить документы на сервер",
  Downloads: "Файлы для загрузки",
  Consider: "Рассмотреть",
  Answer: "Ответить",
  ViewAnswer: "Посмотреть ответ",
  Adress: "Адресс",
  EnterAdress: "Введите адресс",
  SenderStatus: "Статус отправителя",
  ToWhom: "Кому",
  Passport: "Паспорт",
  Chancery: "Канцелярия",
  EnterChanceryNumb: "Введите номер канцеляри",
  Faks: "Факс",
  EnterFaks: "Введите факс",
  Email: "Эл. почта",
  EnterEmail: "Введите эл.почту",
  Bus: "Автобусы",
  EnterBuses: "Введите номера автобусов",
  MiniBus: "Маршрутки",
  EnterMinibuses: "Введите номера маршруток",
  Metro: "Метро",
  EnterMetro: "Введите станция метро",
  ReferencePoint: "Ориентир",
  EnterLandmark: "Введите ориентир",
  WorkingTime: "Рабочая время",
  selected: "выбранное",
  goToSite: "Перейти на сайт",

  // me
  headerLang: "Языки заголовок",
  allNews: "Все новости",
  search: "Поиск",
  exit: "выход",
  exitToSite: " Хотите выйти из системы?",
  back: "Назад",

  notOnMain: " Не на главную",
  onMain: " На главную",
  title: " Заголовок",
  publicationDate: "Дата публикации",
  ingradient: "Содержимое",
  Img: "Фото",
  Documents: "Документы ",
  documentFiles: "Файлы документы",
  Choose: "Выбрать",

  postCount: "Количество",
  count: "штук",
  Specialty: "Специальность",
  Degree: "Образование",

  Published: "Опубликован",
  NotPublished: "Не опубликован",

  APopconfirm: "Вы действительно хотите удалить?",
  Yes: "Да",
  No: "Нет",
  SucDelete: "Меню успешно удален",
  NoDelete: "Ошибка при удалении",
  NoDelete2: "Не удалось удалить",

  SelectMenu: "Выберите меню",
  positionPlac: "Введите позицию",
  EnterUrlLink: "Введите url(для внешних ссылок)",
  EditorErrTitle: "Введите контент на одном из языков.",
  ImageStorages: "Хранилище изображений",
  Close: "Закрыть",
  ReleaseHome: "Релиз на главной странице",
  EditorErrTitle2: "Введите заголовок на одном из языков.",
  On: "Вкл",
  Off: "Выкл",
  All: "Все",
  PlenarySession: "Пленарное заседание",
  IsDate: "Дата",
  YouTubeLink: "ссылка на ютуб",
  CommissionsMembers: "Члены комиссии",
  CommissionsPowers: "Полномочия комиссии",
  Alias: "Alias",
  EnterAlias: " Введите Алиас",
  NoAlias: "Данный ЧПУ недоступен",
  Sections: "Разделы",
  Select: "Выбрать",
  CommitteeMembers: "Члены комитета",
  CommitteeCharter: "Устав комитета",
  CommitteeWorkPlan: "План работы комитета",
  LegislativeActivity: "Законодательная деятельность",
  MonitoringActivities: "Мониторингово-аналитическая деятельность",
  Events: "События",
  HandlingApplications: "Обработка приложений",
  EmployeeType: "Тип сотрудника",
  UploadImage: "Загрузить изображение",
  FullName: "Ф.И.О",
  Position: "Должность",
  PositionPlace: "Введите должность",
  Tasks: "Задания",
  Senator: "Сенатор",
  Order: "порядок",
  Biography: "Биография",
  PositionEnter: "Введите позицию",
  SocialNetwork: "Социальная сеть",
  Type: "Тип",
  Value: "Ценить",
  Placeholder: "Введите",
  Employee: "Cотрудника",
  DevonianActivity: "Девонская активность",
  Departments: "Департаменты и отделы",
  Link: "Cсылка",
  Articles: "Статьи",
  IsArticles: "Статьи",
  ManagementName: "Название управления",
  More: "Подробнее",
  OfficeSenate: "Канцелярия Сената",
  OrthographicLover: "Орфографическая ошибка",
  Description: "Описание",
  DataTransfers: "Дата отправки",
  linkError: "Ссылка на ошибку",
  Go: "Перейти",
  Question: "Вопрос",
  Fixed: "Исправлено",
  NoFixed: "Не исправлено",
  NotIndicated: "Не указано",
  PublicationName: "Название публикации",
  SelectSenator: "Выбрать сенатора",
  Result: "Результат",
  Answers: "Ответы",
  QuestionText: "Савол матни",
  Subtitle: "Подзаголовок",
  RemoveSelected: "Снять выделенные",
  DeleteSelected: "Удалить выделенные",
  AllDelete: "Удалить все",
};

export default ru;
